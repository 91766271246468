
.tonetool {
  width:90vw;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;

 > * {
   margin: 4px;
 }
}

.button {
  padding: 8px;
  min-width: 80px;
  border-radius: 2px;
  color: black;
  font-weight: bold;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: #ebebeb;
  }
}

.button--start {
  background-color: lightgreen;
  &:hover {
    background-color: rgb(195, 245, 195);
  }
}


.button--stop {
  background-color: lightcoral;
  &:hover {
    background-color: rgb(255, 157, 157);
  }
}

.button--vol {
  min-width: 32px;
}
